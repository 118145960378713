import React from "react";
import Footer from "./footer";
import Header from "./header";

import styled from "@emotion/styled";

const Container = styled.div`
  min-height: 100vh;
`;

export interface LayoutProps {
  transparentHeaderOnTop?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  transparentHeaderOnTop,
}) => {
  return (
    <div>
      <Header transparentOnTop={transparentHeaderOnTop} />
      <Container>{children}</Container>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  transparentHeaderOnTop: true,
};

export default Layout;
