import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import {
  faInstagram,
  faFacebookF,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { graphql, useStaticQuery } from "gatsby";

/**
 * Footer wrapper, styled component for wrap footer
 */
const Wrapper = styled.footer(({ theme }: any) => ({
  backgroundColor: "#0A286A",
  borderRadius: "1em 1em 0 0",
  overflow: "hidden",
}));

type FooterHeadingProps = {
  fontWeight?: number;
};

const FooterHeading = styled.h2((props: FooterHeadingProps) => ({
  color: "white",
  fontWeight: props.fontWeight || 700,
  display: "block",
  lineHeight: 1.4,
}));

type ContactItemProps = {
  title: string;
  value: string;
};

const ContactItem: React.FunctionComponent<ContactItemProps> = ({
  title,
  value,
}) => (
  <div
    id="contact"
    css={css`
      padding-bottom: 1em;
      font-size: 0.9em;
    `}
    className="has-text-centered has-text-left-tablet"
  >
    <strong css={css({ color: "white" })}>{title}</strong>
    <span
      css={css`
        display: block;
        color: white;
      `}
    >
      {value}
    </span>
  </div>
);

export interface FooterProps {}

const Footer: React.FC<FooterProps> = props => {
  const { site } = useStaticQuery(query);

  const {
    phone,
    mail,
    address,
    instagramUrl,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
  } = site.siteMetadata.contact;

  return (
    <Wrapper>
      <div className="container px-6" css={{ padding: "8em 0" }}>
        <div className="columns is-3">
          <Fade bottom>
            <div className="column is-three-quarters-tablet has-text-centered has-text-left-tablet mb-3">
              <FooterHeading className="mb-0 display-5" fontWeight={100}>
                ¿Tienes una gran idea?
              </FooterHeading>
              <FooterHeading className="display-5">
                No esperes para hacerla realidad, contáctanos.
              </FooterHeading>
            </div>
          </Fade>

          <Fade right>
            <div className="column" css={{ a: { textDecoration: "none" } }}>
              <a
                target="__blank"
                href="https://api.whatsapp.com/send?phone=573147315640"
              >
                <ContactItem title="Teléfono" value={phone} />
              </a>
              <a href={`mailto: ${mail}`}>
                <ContactItem title="E-mail" value={mail} />
              </a>
              <ContactItem title="Visítanos" value={address} />

              <div
                className="columns is-mobile mt-4"
                css={css`
                  & > * {
                    transition: transform 0.3s ease;

                    :hover {
                      transform: scale(1.5) rotate(20deg);
                      cursor: pointer;
                    }
                  }
                `}
              >
                <div className="column has-text-centered">
                  <a href={instagramUrl} target="_blank">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      color="white"
                      size="lg"
                    />
                  </a>
                </div>

                <div className="column has-text-centered">
                  <a href={twitterUrl} target="_blank">
                    <FontAwesomeIcon icon={faTwitter} color="white" size="lg" />
                  </a>
                </div>

                <div className="column has-text-centered">
                  <a href={facebookUrl} target="_blank">
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      color="white"
                      size="lg"
                    />
                  </a>
                </div>

                <div className="column has-text-centered">
                  <a href={linkedinUrl} target="_blank">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      color="white"
                      size="lg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Wrapper>
  );
};

const query = graphql`
  query Contact {
    site {
      siteMetadata {
        contact {
          phone
          mail
          address
          instagramUrl
          facebookUrl
          linkedinUrl
          twitterUrl
        }
      }
    }
  }
`;

export default Footer;
