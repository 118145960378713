import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import BurgerMenu from "./burger-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: ${({ hasBackground }: any) =>
    hasBackground ? "rgba(255, 255, 255, 0.95)" : "transparent"};
  box-shadow: ${({ hasBackground }: any) =>
    hasBackground ? "0 0 10px rgba(0, 0, 0, 0.3)" : "none"};
  padding: 0.5em 1em;
  transition: background-color 0.3s ease-in-out, box-shadow 0.4s ease;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const HeaderLogo = styled.img`
  height: 50px;

  @media (min-width: 768px) {
    height: 60px;
  }
`;

const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: ${({ show }: { show: boolean }) => (show ? 0 : -100)}%;
  height: 100%;
  width: 100%;
  background-color: white;
  transition: right 0.4s ease;
  padding-top: 60px;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.5);
  z-index: -1;

  @media (min-width: 768px) {
    flex-direction: row;
    position: relative;
    background-color: transparent;
    height: auto;
    width: auto;
    padding-top: auto;
    right: auto;
    z-index: auto;
    box-shadow: none;
    padding-top: 0;
  }
`;

const NAVBAR_LINKS: { title: string; path: string }[] = [
  {
    title: "Sobre nosotros",
    path: "/about",
  },
  {
    title: "Proyectos",
    path: "/projects",
  },
  {
    title: "Blog",
    path: "/blogs",
  },
  {
    title: "Contáctanos",
    path: "#contact",
  },
];

export interface HeaderProps {
  transparentOnTop?: boolean;
}

const Header: React.FC<HeaderProps> = ({ transparentOnTop }) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  const scrollHandler = () => {
    const { scrollTop } = document.documentElement;

    setIsOnTop(scrollTop === 0);
  };

  const toggleMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    // Initial call to verify position on the screen
    scrollHandler();

    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <HeaderWrapper hasBackground={!isOnTop || !transparentOnTop}>
      <HeaderContent className="container">
        <Link to="/">
          <HeaderLogo
            src="/images/logos/logo-black.svg"
            className="mb-0"
            alt="Cubit logo"
          />
        </Link>
        <div css={{ "@media (min-width: 768px)": { display: "none" } }}>
          <BurgerMenu opened={showMenu} onClick={toggleMenu} />
        </div>
        <NavBar show={showMenu}>
          {NAVBAR_LINKS.map(({ path, title }, index) => (
            <Link
              key={`link-${index}`}
              className="text-primary"
              css={theme => ({
                textDecoration: "none",
                padding: "1em",
                fontWeight: 400,
                fontSize: "1.2em",
                position: "relative",
                transition: "all 150ms ease",
                borderRadius: "0.5em",
                ":hover": {
                  color: "white",
                  backgroundColor: "#0A286A",
                },
                "@media (min-width: 768px)": {
                  color: isOnTop && transparentOnTop ? "white" : "#0A286A",
                },
              })}
              onClick={toggleMenu}
              to={path}
            >
              {title}
            </Link>
          ))}
        </NavBar>
      </HeaderContent>
    </HeaderWrapper>
  );
};

Header.defaultProps = {
  transparentOnTop: true,
};

export default Header;
